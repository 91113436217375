<template>
	<div style="padding-bottom: 100px">
		<div class="Main width_1200 margin_auto" style="padding-bottom: 20px">
			<div class="bmBox flex justify_content_sb">
				<div class="bmBox_left margin_left_20_media">
					<div class="bmBox_left_1"
						>{{ activityDetails.release_time }} 一{{
							activityDetails.end_time
						}}</div
					>
					<div class="bmBox_left_2">
						{{ activityDetails.title }}
					</div>
					<div class="bmBox_left_3">
						主办方：{{ activityDetails.organizer }}
					</div>
				</div>
				<div class="bmBox_right">
					<div class="bmBox_right_1 flex align_items_c">
						<span>价格：</span>
						<span>¥RS{{ activityDetails.price }}</span>
					</div>
					<!-- <div class="bmBox_right_2 flex align_items_c justify_content_c">
                        立即报名
                    </div> -->
				</div>
				<div> </div>
			</div>
			<div class="flex justify_content_sb" style="margin-top: 60px">
				<div class="MainLeft margin_left_20_media">
					<div class="MainLeft_1">
						<img
							v-lazy="activityDetails.pic"
							:key="activityDetails.pic"
							alt=""
						/>
					</div>
					<div class="MainLeft_2">
						<p v-html="activityDetails.content"></p>
					</div>
				</div>
				<div class="MainRight margin_right_20_media">
					<div class="MainRight_1">
						<div class="MainRight_1_item flex align_items_s">
							<img
								src="https://zy.metaera.media/assets/images/detail/5.png"
								alt=""
							/>
							<span
								>{{ activityDetails.start_time }} 一{{
									activityDetails.end_time
								}}</span
							>
						</div>
						<div class="MainRight_1_item flex align_items_s">
							<img
								src="https://zy.metaera.media/assets/images/detail/6.png"
								alt=""
							/>
							<span
								>{{ activityDetails.province }}{{ activityDetails.city
								}}{{ activityDetails.area }}{{ activityDetails.address }}</span
							>
						</div>
						<div class="MainRight_1_item flex align_items_s">
							<img
								src="https://zy.metaera.media/assets/images/detail/7.png"
								alt=""
							/>
							<span>{{ activityDetails.num }} 人</span>
						</div>
					</div>
					<!-- <div class="MainRight_2 flex align_items_c justify_content_c">
                        <img src="https://zy.metaera.media/assets/images/detail/8.png" alt="">
                        <span>发布活动</span>
                    </div> -->
					<div class="MainRight_6">
						<div class="MainRight_6_title">24小时热文</div>
						<othresou></othresou>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getActivityDetails, homeLinsts } from "@/api/home";
export default {
	name: "",
	data() {
		return {
			activityDetails: {},
		};
	},
	created() {
		this.getActivityDetails();
	},
	methods: {
		async getActivityDetails() {
			// this.$loading.show()
			await getActivityDetails({ id: this.$route.query.id })
				.then((res) => {
					if (res.code == 200) {
						this.activityDetails = res.data;
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
			this.$loading.hide();
		},
	},
};
</script>

<style scoped>
.Main {
	padding-top: 20px;
}

.MainLeft {
	width: 760px;
}

.bmBox_left {
	width: 760px;
}

.bmBox_left_1 {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #909099;
}

.bmBox {
	padding-bottom: 35px;
	border-bottom: 1px solid #eaeaee;
	width: 100%;
}

.bmBox_left_2 {
	font-size: 30px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
	margin-top: 11px;
}

.bmBox_left_3 {
	font-size: 16px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
	margin-top: 13px;
}

.bmBox_right {
	width: 299px;
}

.bmBox_right_1 span:nth-child(1) {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
}

.bmBox_right_1 span:nth-child(2) {
	font-size: 27px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #ea3e3e;
	margin-left: 8px;
}

.bmBox_right_2 {
	width: 170px;
	height: 38px;
	background: linear-gradient(133deg, #8a11ff 0%, #176dff 100%);
	border-radius: 2px 2px 2px 2px;
	opacity: 1;
	margin-top: 26px;
	font-size: 16px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #ffffff;
}

.MainLeft_1 {
	width: 100%;
	height: 417px;
}

.MainLeft_1 img {
	width: 100%;
	height: 100%;
}

.MainLeft_2 {
	margin-top: 35px;
}

.MainLeft_2 p {
	margin-top: 20px;
	font-size: 18px;
	font-family: SF Compact-Regular, SF Compact;
	font-weight: 400;
	color: #29293b;
	line-height: 38px;
}

.MainRight {
	width: 372px;
}

.MainRight_1 {
	padding: 35px 0 0 21px;
	padding-bottom: 50px;
	background: #ffffff;
	border-radius: 0px 0px 0px 0px;
	opacity: 1;
	border: 1px solid #dcdcdc;
}

.MainRight_1_item {
	margin-top: 32px;
	width: 90%;
}

.MainRight_1_item:nth-child(1) {
	margin-top: 0;
}

.MainRight_1_item img {
	width: 20px;
}

.MainRight_1_item span {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
	margin-left: 10px;
}

.MainRight_2 {
	height: 50px;
	background: #ffffff;
	border-radius: 2px 2px 2px 2px;
	opacity: 1;
	border: 1px solid #0056ff;
	margin-top: 30px;
}

.MainRight_2 img {
	width: 15px;
}

.MainRight_2 span {
	font-size: 16px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #0056ff;
	margin-left: 4px;
}

.MainRight_6 {
	margin-top: 32px;
}

.MainRight_6_title {
	font-size: 18px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
}

.dexBoxm {
	margin-top: 20px;
}

.wzitemLTime {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #999999;
}

.wzitem {
	height: 105px;
	margin-top: 20px;
}

.wzitem2Img {
	width: 240px;
	height: 105px;
}

.wzitem2Img img {
	width: 100%;
	height: 100%;
}

.wzitemL {
	height: 100%;
}
</style>
